import type { NavigationFailure, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { useSelectionStore } from '~/store/selection-store'
import { countries } from '~/constants'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized): Promise<void | NavigationFailure | false | RouteLocationRaw> => {
  if (import.meta.client) {
    // Redirect to home if no partnerId is provided
    if (!to.query.partnerId) {
      return navigateTo('/')
    }
    const selectionStore = useSelectionStore()
    const partnerId = to.query.partnerId as string
    const partnerDomain = countries.find(country => country.partners.find(partner => partner.id === partnerId))?.partners.find(partner => partner.id === partnerId)?.domain
    // Redirect to home if no partnerDomain is found for the provided partnerId
    if (!partnerDomain) {
      return navigateTo('/')
    }

    // Fetch stores for the partner
    const response = await selectionStore.getStores({ subdomain: partnerDomain })
    if (response?.error) {
      return navigateTo('/')
    }
  }
})
